import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

	static targets = ['title', 'salutation', 'firstname', 'lastname', 'email', 'phone', 'companyname', 'idmethod', 'streetandno', 'zip', 'city', 'tier', 'premium', 'tablestart', 'tableclassic', 'tablepremium', 'date', 'terms', 'businesssector', 'salesvolume', 'inkasso'];

	mapping = {
		salutation: {
			"0": "",
			"1": "Frau",
			"2": "Herr",
		},
		"id_method": {
			"3": "Handelsregisternummer",
			"1": "CreFo-Nummer",
			"2": "unbekannt"
		},
		"tier": {
			"0": "Start",
			"1": "Klassik",
			"2": "Premium"
		},
		"inkasso": {
			"0": "nein",
			"1": "ja"
		}
	}
	_minimum(fd) {
		const tier = fd.get('quotation[tier]');

		if (tier == 1) { // Classic
			return 1200;
		} else if (tier == 2) { // Premium
			return 1400;
		}

		return 1000; // Start
	}

	_premiumFactor(fd, bracketFactor) {
		const premiumFactor = bracketFactor * this._tierFactor(fd);

		return premiumFactor;
	}

	_tierFactor(fd) {
		const tier = fd.get('quotation[tier]');

		if (tier == 1) { // Classic
			return 1.0;
		} else if (tier == 2) { // Premium
			return 1.2;
		}

		return 0.7; // Start
	}

	terms(fd) {

		const tier = fd.get('quotation[tier]');

		if (tier == 1) {
			this.termsTarget.href = 'https://atradius.de/documents/vertragsbedingungen-atradius-direkt-klassik.pdf';
			return;
		} else if (tier == 2) {
			this.termsTarget.href = 'https://atradius.de/documents/vertragsbedingungen-atradius-direkt-premium.pdf';
			return;
		}

		this.termsTarget.href = 'https://atradius.de/documents/vertragsbedingungen-atradius-direkt-start.pdf';
	}

	async _premium(fd) {
		const salesVolume = parseInt(fd.get('quotation[sales_volume]').replaceAll(/\./g, ''));
		const businessSectorId = parseInt(fd.get('quotation[business_sector_id]'));

		if (isNaN(salesVolume) || isNaN(businessSectorId)) {
			return;
		}

		const promise = fetch(`/anfrage/bracket-factor?sales_volume=${salesVolume}&business_sector_id=${businessSectorId}`).
			then(response => {
				return response.json();
			});

		const bracketFactor = await promise;

		let premium = salesVolume * (this._premiumFactor(fd, bracketFactor) / 100);
		const min = this._minimum(fd);


		if (min > premium) {
			premium = min;
		}

		const inkasso = fd.get('quotation[inkasso]');

		if (inkasso == 1) {
			premium *= 1.1;
		}

		return Math.round(premium);
	}

	async _monthly(fd) {

		const tmpTier = fd.get('quotation[tier]');

		fd.set('quotation[tier]', 0)
		const monthlyStart = await this._premium(fd);

		fd.set('quotation[tier]', 1)
		const monthlyClassic = await this._premium(fd);

		fd.set('quotation[tier]', 2)
		const monthlyPremium = await this._premium(fd);

		fd.set('quotation[tier]', tmpTier);

		return {
			start: monthlyStart,
			classic: monthlyClassic,
			premium: monthlyPremium
		};
	}

	_addDays(date, days) {
		let result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	}

	date(event) {
		const target = event.currentTarget;
		let value = target.value;

		if (!value) {
			let date = this._addDays(new Date(), 1);
			const offset = date.getTimezoneOffset();
			value = date.toISOString().split('T')[0];
		}

		this.dateTarget.min = value;
	}

	update(event) {
		const formData = new FormData(event.currentTarget.form);

		const salutation = formData.get('quotation[salutation]');
		this.salutationTarget.innerHTML = this.mapping.salutation[salutation];

		const title = formData.get('quotation[title]') || "";
		this.titleTarget.innerHTML = title;

		const firstname = formData.get('quotation[first_name]');
		this.firstnameTarget.innerHTML = firstname;

		const lastname = formData.get('quotation[last_name]');
		this.lastnameTarget.innerHTML = lastname;

		const email = formData.get('quotation[email]');
		this.emailTarget.innerHTML = email;

		const phone = formData.get('quotation[phone]');
		this.phoneTarget.innerHTML = phone;

		const companyName = formData.get('quotation[company_name]');
		this.companynameTarget.innerHTML = companyName;

		const idMethod = formData.get('quotation[id_method]');
		this.idmethodTarget.innerHTML = `Identifikation durch: ${this.mapping["id_method"][idMethod]}`;

		const streetAndNo = formData.get('quotation[street_and_no]');
		this.streetandnoTarget.innerHTML = streetAndNo;

		const zip = formData.get('quotation[zip]');
		this.zipTarget.innerHTML = zip;

		const city = formData.get('quotation[city]');
		this.cityTarget.innerHTML = city;

		const tier = formData.get('quotation[tier]');
		this.tierTarget.innerHTML = this.mapping["tier"][tier];

		const inkasso = formData.get('quotation[inkasso]');
		this.inkassoTarget.innerHTML = this.mapping["inkasso"][inkasso];

		const salesVolume = formData.get('quotation[sales_volume]');
		this.salesvolumeTarget.innerHTML = `${salesVolume}&nbsp;€`;

		const businessSectorId = formData.get('quotation[business_sector_id]');

		const business_sector_name = document.querySelector(`option[value="${businessSectorId}"]`).text;
		this.businesssectorTarget.innerHTML = business_sector_name;

		this.terms(formData);

		// Skip calculations if we haven't input Business Sector OR Sales Volume.
		if (businessSectorId == '' || salesVolume == '' || tier == '') {
			//console.log('we empty; skip');
			return;
		}

		// Skip calculations if both values were left unchanged.
		if (this.businessSectorId == businessSectorId && this.salesVolume == salesVolume && this.tier == tier && this.inkasso == inkasso) {
			return;
		}

		// Hold back values to compare for next time this function runs.
		this.salesVolume = salesVolume;
		this.businessSectorId = businessSectorId;
		this.tier = tier;
		this.inkasso = inkasso;

		// Async; do not move!
		this._monthly(formData).
			then(((fd, value) => {
				const locale = new Intl.NumberFormat('de-DE');

				this.tablestartTarget.innerHTML = `${locale.format(value['start'])}&nbsp;€`;
				this.tableclassicTarget.innerHTML = `${locale.format(value['classic'])}&nbsp;€`;
				this.tablepremiumTarget.innerHTML = `${locale.format(value['premium'])}&nbsp;€`;

				const tier = parseInt(fd.get('quotation[tier]'));
				let tierValue = value.start;

				if (tier == 1) {
					tierValue = value.classic;
				} else if (tier == 2) {
					tierValue = value.premium;
				}



				this.premiumTarget.innerHTML = `${locale.format(tierValue)}&nbsp;&euro;`

			}).bind(this, formData));


	}


}
