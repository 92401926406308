import { Controller } from "@hotwired/stimulus";


export default class Form extends Controller {

	static classes = ['hiding'];

	static targets = ['group', 'notice', 'progress'];

	static values = {
		validations: Object,
	};


	initialize() {

	}

	connect() {

		super.connect();


		// Hide all except first group.
		const initalGroupTargets = this.groupTargets;
		initalGroupTargets.shift();
		this._hide(initalGroupTargets);

		// Workaround to disable button on init.
		document.querySelector('[data-tabs-target="panel"]').dispatchEvent(new CustomEvent('panel-incomplete'));

		// Init progress
		this._updateProgress();
	}

	_to_array(target) {
		let targets = target;
		if (targets.constructor !== Array) {
			targets = [target]
		}

		return targets;
	}

	/**
	 * Hide a HTMLElement or an Array of HTMLElements.
	 *
	 * @param {*} target
	 */
	_hide(target) {

		const targets = this._to_array(target);
		targets.forEach((node, _index) => {

			node.classList.add(this.hidingClass);
		});
	}

	_show(target) {

		const targets = this._to_array(target);
		targets.forEach((node, _index) => {

			node.classList.remove(this.hidingClass);
		});
	}

	disconnect() {

		this._show(this.groupTargets);
	}

	/**
	 * Progress bar is based on completed tab panels.
	 */
	_updateProgress() {

		const allPanelsCount = this.element.querySelectorAll('[data-tabs-target="panel"]').length;
		const completedPanelsCount = this.element.querySelectorAll('[data-tabs-target="panel"][data-completed="true"]').length;
		const progressFactor = Math.min(Math.max(completedPanelsCount / allPanelsCount, 0), 1);

		this.progressTarget.style.setProperty('--tw-scale-x', progressFactor);
	}

	_requireTest(node) {

	}

	_validate(node) {

		return node.validity.valid;
	}

	_checkGroupConstraints(group) {

		if (!group) {
			return;
		}

		const key = group.dataset.key;
		const expectedValue = group.dataset.value;
		const op = group.dataset.operation;

		if (undefined === key || undefined === expectedValue) {
			return true;
		}

		const formData = new FormData(group.closest('form'));
		const receivedValue = formData.get(key);

		if ('gt' == op) {
			return receivedValue > expectedValue;
		}

		return expectedValue === receivedValue;
	}

	_disableGroup(group) {

		if (!group) {
			return;
		}

		group.querySelectorAll('input').forEach((field) => {

			field.value = '';
			field.dispatchEvent(new Event('change'));

			if (field.required) {
				field.dataset.required = true;
				field.required = false;
			}
		});

		group.classList.add(this.hidingClass);
	}

	_enableGroup(group) {

		group.querySelectorAll('input').forEach((field) => {

			if (field.dataset.required) {
				field.required = true;
			}
		});

		group.classList.remove(this.hidingClass);
	}

	_nextGroup(currentGroup) {
		const groupIndex = this.groupTargets.indexOf(currentGroup);

		// Clamp the index to avoid OutOfBoundsException, and show next group.
		const nextGroupIndex = Math.min(Math.max(groupIndex + 1, 0), this.groupTargets.length);
		let nextGroup = this.groupTargets[nextGroupIndex];


		if (!this._checkGroupConstraints(nextGroup)) {

			this._disableGroup(nextGroup);
			return this._nextGroup(nextGroup);
		} else {
			// console.log('passed', nextGroup);
		}

		return nextGroup;
	}

	_checkAllGroups() {

		const allGroups = this.groupTargets.forEach((group, _index) => {
			if (!this._checkGroupConstraints(group)) {
				this._disableGroup(group);
				return;
			}

		});
	}

	check(event) {
		const input = event.currentTarget;
		let notice = input.nextElementSibling;

		if (input?.dataset?.notice) {
			notice = document.querySelector(input.dataset.notice);
		}

		if (input.validity.valueMissing) {
			notice.innerHTML = input.validationMessage;
			return;
		}

		if (!input.validity.valid) {
			notice.innerHTML = input.dataset.invalidMessage || input.validationMessage;
			return;
		}

		notice.innerHTML = '';
	}

	validate(event) {

		const currentGroup = event.target.closest('[data-form-target="group"]');

		if (!currentGroup) {
			return;
		}

		if (currentGroup.classList.contains('hidden')) {
			return;
		}

		this._checkAllGroups();

		const nextGroup = this._nextGroup(currentGroup);
		this._enableGroup(nextGroup);

		// Unlock next step if available.
		const currentPanel = currentGroup.closest('[data-tabs-target="panel"]');
		const completed = 0 === currentPanel.querySelectorAll(':invalid').length;

		if (completed) {
			currentPanel.dispatchEvent(new CustomEvent('panel-completed'));
			currentPanel.dataset.completed = completed;
		} else {
			currentPanel.dispatchEvent(new CustomEvent('panel-incomplete'));

			if (undefined !== currentPanel.dataset.completed) {
				currentPanel.dataset.completed = completed;
			}
		}


		this._updateProgress();

	}

	ga(event) {

		if (typeof gtag !== 'function') {
			return;
		}

		gtag('event', 'submit', {
			'event_category': 'form',
			'event_label': 'completed',
			'value': true,
		});
	}
}
