import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

	static targets = ['element'];



	connect() {
	}

	open(event) {

		if (event.currentTarget.dataset?.condition === undefined) {
			this._open(event);
			return;
		}


		const condition = parseInt(event.currentTarget.dataset?.condition);
		const key = event.keyCode || event.charCode;

		// Never show modal on backspace or DEL character.
		if (key == 8 || key == 46) {
			return;
		}

		const value = parseInt(event.currentTarget.value.replace(/\./g, ''));


		if (value >= condition) {
			this._open(event);
		}

	}

	frisk(event) {
		const target = event.currentTarget;
		const formData = new FormData(target.form);
		const insureProd = parseInt(formData.get('quotation[insure_production_costs_before_delivery]'));

		if (insureProd) {
			this._open(event);
		}
	}

	_open(event) {

		event.stopImmediatePropagation();

		const target = event.currentTarget;
		const modalSelector = target.dataset?.modal;

		if (!modalSelector) {
			return;
		}

		const modal = document.querySelector(modalSelector);
		modal.classList.remove('hidden');
	}

	close(event) {
		const target = event.currentTarget;
		const modal = target.closest('[data-maybemodal-target="container"]');
		modal.classList.add('hidden');
	}


}
