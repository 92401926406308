const defaultTheme = require('tailwindcss/defaultTheme');
const plugin = require('tailwindcss/plugin');

module.exports = {
	content: [
		'./public/*.html',
		'./app/helpers/**/*.rb',
		'./app/assets/javascript/**/*.js',
		'./app/views/**/*.{erb,haml,html,slim}'
	],
	theme: {
		extend: {

			backgroundImage: {
				pattern: "url('pattern.svg')",
				lines: ''
			},
			boxShadow: {
				DEFAULT: '2px 1px 7px #939393',
			},
			colors: {
				gray: {
					DEFAULT: '#939393',
					charcoal: '#404040',
					bright: '#ebebeb',
					iron: '#d8d8d8',
					smoke: '#707070',
					todo: '#f8f8f8',
					pale: '#bfbfbf'
				},
				green: {
					DEFAULT: '#598735',
				},
				red: {
					DEFAULT: '#dc0028',
					venetian: '#dc0028',
					peach: '#f8ebea',
					rose: '#eac5c4',
					orchid: '#d99694'
				},
				white: {
					DEFAULT: '#fff',
					alabaster: '#f8f8f8',
					smoke: '#f4f4f4'
				}
			},
			fontFamily: {
				sans: ['Catalana Sans', ...defaultTheme.fontFamily.sans],
				serif: ['Catalana', ...defaultTheme.fontFamily.serif]
			},
			fontSize: {
				inherit: ['inherit', 'inherit'],
				'2xs': ['0.625rem', 1.7], // 10px
				xs: ['0.75rem', 1.417], // 12px
				sm: ['0.875rem', 1.429], // 14px
				base: ['1rem', 1.29], // 16px
				lg: ['1.125rem', 1.2222], // 18px
				xl: ['1.25rem', 1.15], // 20px
				'2xl': ['1.5rem', 1.1667], // 24px
				'3xl': ['2.813rem', 1.1111] // 45px

			},
			letterSpacing: {
				widest: '0.161rem'
			},
			minHeight: {
				input: '36px',
			}

		},
		screens: {
			sm: '640px',
			md: '768px',
			lg: '1024px',
			xl: '1200px',
		}
	},
	plugins: [
		require('@tailwindcss/forms'),
		require('@tailwindcss/aspect-ratio'),
		require('@tailwindcss/typography'),
		plugin(({ addVariant }) => {
			addVariant('not-last', '&:not(:last-child)')
		})
	]
}
