import { Tabs } from "tailwindcss-stimulus-components"

export default class TabsMultiStep extends Tabs {

	static classes = ['completed', 'inProgress'];

	static targets = ['prev', 'next', 'nextwrap', 'counter', 'label', 'radioselect'];

	static values = {
		selectLabel: { type: String, default: 'Auswählen' },
		selectedLabel: { type: String, default: 'Ausgewählt' },
		nextLabel: { type: String, default: 'Weiter' },
		submitLabel: { type: String, default: 'Absenden' },
		stepsSingularLabel: { type: String, default: 'Schritt' },
		stepsPluralLabel: { type: String, default: 'Schritte' }
	};

	connect() {
		super.connect();
		this.data.set('index', 0)
		this.maxStep = 0;
		this._ga(0);
		window.dispatchEvent(new CustomEvent('tsc:tab-change'))
	}

	/**
	 *
	 * @param {int} step
	 * @returns void
	 */
	_ga(step) {

		if (typeof gtag !== 'function') {
			return;
		}

		const labels = {
			na: "N/A",
			1: "General",
			2: "Requirements",
			3: "Request",
			4: "Personal",
			5: "Summary"
		}

		const stepId = parseInt(step) + 1; // Humans start counting from one (1).
		const stepLabel = labels.hasOwnProperty(stepId) ? labels[stepId] : labels['na'];
		const formData = new FormData(document.querySelector('[data-form-target="form"]'));

		window.history.pushState({stepId: stepId, stepLabel: stepLabel, data: JSON.stringify(formData)}, '', `/angebot/${stepId}`);

		if (this.index <= this.maxStep) {
			return;
		}

		gtag('event', 'changeStep', {
			'event_category': 'form',
			'event_label': stepLabel,
			'value': stepId,
		});
		this.maxStep = this.index;
	}

	/**
	 * Workaround function for table panel.
	 * Sets the completed attribute so we don't have to click twice
	 * @param {*} event
	 */
	radioselect(event) {

		this.panelTargets[this.index].dataset.completed = true;

		this.radioselectTargets.forEach(t => {
			t.innerHTML = this.selectLabelValue;
		})

		event.currentTarget.innerHTML = this.selectedLabelValue;
		event.currentTarget.nextElementSibling.dispatchEvent(new Event('input'));


	}

	change(event) {

		const currentIndex = parseInt(this.data.get('index'));
		const currentPanel = this.panelTargets[currentIndex];
		const direction = event.currentTarget.dataset.direction;
		const clickedTab = this.tabTargets.indexOf(event.currentTarget);


		currentPanel.querySelectorAll(':invalid').forEach((el) => {
			el.dispatchEvent(new Event('blur'));
		});


		// Do nothing if attempting to navigate to currently visible tab.
		if (clickedTab === currentIndex) {
			return;
		}

		// Check if current Step was completed, when next button is clicked.
		if ('next' === direction) {

			if (currentPanel.dataset.completed != 'true') {
				return;
			}

			if (this.panelTargets.length - 1 === this.index) {
				event.currentTarget.closest('form').submit();
			}

		}

		// Check if navigation to completed step when tab is clicked.
		if (-1 < clickedTab) {

			const completedPanels = this.element.querySelectorAll('[data-completed]');


			if (clickedTab > completedPanels.length) {
				return;
			}


		}

		// We're allowed to navigat to another step. Save previous step index.
		this.data.set('previousIndex', currentIndex);

		if (event.currentTarget.dataset.direction) {
			this.index = this.data.get('index');

			if ('next' === direction) {

				this.index = Math.min(++this.index, this.tabTargets.length - 1);
			} else if ('prev' === event.currentTarget.dataset.direction) {
				this.index--;
			}

			// Otherwise, use the index of the current target
		} else {
			this.index = clickedTab;
		}

		this._ga(this.index);
		window.dispatchEvent(new CustomEvent('tsc:tab-change'))
		window.dispatchEvent(new Event('resize')); // Workaround for sameheight controller.

		// SUmmary Panel workaround
		if (this.index == 5) {
			const submit = this.panelTargets[this.index].querySelector('[type="submit"]');
			this.nextwrapTarget.classList.add('invisible');
			submit.disabled = true;
			return;
		}

		// Workaround to disable button on init.
		const inpt = this.panelTargets[this.index].querySelector('input[data-action],select[data-action]');

		if (inpt != null && inpt.tagName == 'INPUT' && inpt.id !== 'quotation_accept_insurance_conditions') {

			const el = this.panelTargets[this.index].querySelector('input[data-action]');
			el.dispatchEvent(new Event('input'));
			el.dispatchEvent(new Event('change')); // for Radio
		} else if (inpt != null && inpt.tagName == 'SELECT') {
			this.panelTargets[this.index].querySelector('select[data-action]').dispatchEvent(new Event('change'));
		}



	}

	/**
	 * Activates the finished tab indicator.
	 *
	 * @param {*} event
	 */
	setTabCompleted(event) {
		const panel = event.target;
		const tabIndex = this.panelTargets.indexOf(panel);
		const tab = this.tabTargets[tabIndex];
		tab.querySelector('.steps').classList.add(this.completedClass);

		const last = panel.dataset.panel;

		if (last === 'last') {
			const submit = panel.querySelector('[type="submit"]');
			this.nextwrapTarget.classList.add('invisible');
			submit.disabled = '';
			return;
		} else {
			this.nextwrapTarget.classList.remove('invisible');
		}



		const nextButton = document.querySelector('[data-tabs-target="nextwrap"]');
		nextButton.disabled = false;
	}

	/**
	 * Activates the finished tab indicator.
	 *
	 * @param {*} event
	 */
	setTabInCompleted(event) {
		const panel = event.target;
		const tabIndex = this.panelTargets.indexOf(panel);
		const tab = this.tabTargets[tabIndex];
		tab.querySelector('.steps').classList.remove(this.completedClass);

		const last = panel.dataset.panel;

		if (last === 'last') {
			const submit = panel.querySelector('[type="submit"]');
			this.nextwrapTarget.classList.add('invisible');
			submit.disabled = true;
			return;
		} else {
			this.nextwrapTarget.classList.remove('invisible');
		}



		const nextButton = document.querySelector('[data-tabs-target="nextwrap"]');
		nextButton.disabled = true;
	}

	/**
	 * Activates the in progress tab indicator.
	 * @param {*} event
	 */
	setInProgress(event) {
		const panel = event.target;
		const tabIndex = this.panelTargets.indexOf(panel);
		const tab = this.tabTargets[tabIndex];
		tab.querySelector('.steps').classList.add(this.inProgressClass);
	}

	unsetInProgres(event) {
		const panel = event.target;
		const tabIndex = this.panelTargets.indexOf(panel);
		const tab = this.tabTargets[tabIndex];
		tab.querySelector('.steps').classList.remove(this.inProgressClass);
	}

	/**
	 * Activates the error tab indicator.
	 * @param {*} event
	 */
	setTabError(event) {

	}

	changeStep(_event) {


		try {
			let nextButton = this.nextTarget;
		} catch (e) {
			return;
		}

		let nextButton = this.nextTarget;

		const stepLabel = this.labelTarget;
		const stepCounter = this.counterTarget;
		const tabs = this.tabTargets;

		const lastStep = tabs.length;
		const currentIndex = parseInt(this.data.get('index'));
		const currentStep = currentIndex + 1;
		const stepsLeft = lastStep - currentStep + 1;

		this.setInProgress({ target: this.panelTargets[currentIndex] });

		const previousIndex = this.data.get('previousIndex');
		if (null !== previousIndex) {
			this.unsetInProgres({ target: this.panelTargets[previousIndex] });
		}

		// Change button text between next and submit label.
		if (currentStep === lastStep) {

			nextButton.innerHTML = this.submitLabelValue;

		} else {

			nextButton.innerHTML = this.nextLabelValue;
		}

		// Update the step counter on botton.
		stepCounter.innerHTML = stepsLeft;
		stepLabel.innerHTML = 1 === stepsLeft ? this.stepsSingularLabelValue : this.stepsPluralLabelValue;

		this.element.dataset.currentStep = currentStep;
	}

}
