import { Controller } from '@hotwired/stimulus';
import stickybits from 'stickybits'

export default class extends Controller {
	static values = {
		options: Object
	}

	connect() {

		this.sticky = stickybits(
			this.element,
			{
				useStickyClasses: true,
				...this.optionsValue
			}
		);
	}

	disconnect() {

		this.sticky.cleanup();
	}
}
