import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

	static targets = ['element'];

	connect() {
		window.dispatchEvent(new Event('resize'));
	}

	initialize() {
		this.index = 0;
	}

	setHeight() {

		const row = this.next();

		row.forEach((e) => {
			e.style.height = '';
		});

		// Exit condition: Row is empty.
		if (!row.length) {
			this.initialize();
			return;
		}

		// Fetch the largest element from row.
		const itemHeights = row.map(e => {
			return e.clientHeight;
		})

		const maxHeight = Math.max(...itemHeights);

		// Apply max height to all row elements, but don't allow 0px.
		if (maxHeight) {
			row.forEach((e) => {
				e.style.height = `${maxHeight}px`;
			});
		}

		// Loop recursively until exit condition is met.
		this.setHeight();
	}

	next() {

		let nextRow = [];

		this.elementTargets.forEach(e => {
			nextRow.push(e.children[this.index]);
		});

		// Increment index and filter out empty.
		this.index++;
		return nextRow.filter((e) => {
			return !!e;
		});
	}

	disconnect() {
		this.elementTargets.forEach(e => {
			e.style.height = '';
		});
	}
}
