import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

	static targets = ['start', 'classic', 'premium', 'column', 'date'];


	connect() {
		window.dispatchEvent(new Event('resize'));
	}

	update(event) {
		const formData = new FormData(event.currentTarget.form);

		const germanCustomersOnly = formData.get('quotation[german_customers_only]');
		const insureProductionCostsBeforeDelivery = formData.get('quotation[insure_production_costs_before_delivery]');

		if (null === germanCustomersOnly || null === insureProductionCostsBeforeDelivery) {
			return;
		}

		let target;

		if (1 == germanCustomersOnly && 0 == insureProductionCostsBeforeDelivery) {
			target = this.startTarget;
		} else if (0 == germanCustomersOnly && 0 == insureProductionCostsBeforeDelivery) {
			target = this.classicTarget;
		} else if (0 == germanCustomersOnly && 1 == insureProductionCostsBeforeDelivery) {
			target = this.premiumTarget;
		} else {
			target = this.premiumTarget;
		}

		this.columnTargets.forEach(t => {
			t.classList.remove('recommended');
		});
		target.classList.add('recommended');


	}



	disconnect() {

	}
}
