import { Controller } from '@hotwired/stimulus';
import { debounce } from "lodash";

export default class extends Controller {
	static values = {
		width: Number
	};

	calculateWidth() {

		this.element.setAttribute('style', '');
		this.scrollbarWidthValue = (window.innerWidth - document.body.clientWidth);
		let style = this.element.getAttribute('style');

		if (null === style) {
			style = '';
		}

		style += `--scrollbarWidth: ${this.scrollbarWidthValue}px`;
		this.element.setAttribute('style', style);
	}

	initialize() {

		this.calculateWidth = debounce(
			this.calculateWidth,
			200, {
			leading: false,
			trailing: true
		},
		).bind(this);
	}

	connect() {

		window.addEventListener('resize', this.calculateWidth);
		window.dispatchEvent(new Event('resize'));
	}

	disconnect() {

		window.removeEventListener('scroll', this.calculateWidth);
	}
}
