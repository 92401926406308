import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

	update(event) {
		const value = event.target.value;

		if (!value.length) {
			return;
		}

		// Remove any non-digit characters, except for the decimal separator (comma)
		const cleanedValue = value.replace(/[^\d,]/g, '');

		// Convert the cleaned value to a number, replacing the comma with a dot
		const numberValue = parseFloat(cleanedValue.replace(',', '.'));


		if (isNaN(numberValue)) {

			return;
		}

		const locale = new Intl.NumberFormat('de-DE');
		const formattedNumber = locale.format(numberValue);

		event.target.value = formattedNumber;
	}
}
