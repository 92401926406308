import { Application } from '@hotwired/stimulus';
import { Popover } from "tailwindcss-stimulus-components"
import TabsMultiStep from './controllers/tabs.controller.js';
import MaybeSlideover from './controllers/slideover.controller.js';
import Form from './controllers/form.controller.js';
import Sticky from './controllers/sticky.controller.js';
import SlimSelect from './controllers/slimselect.controller.js';
import SameHeightController from './controllers/sameHeight.controller.js';
import Summary from './controllers/summary.controller.js';
import RecommendationController from './controllers/recommendation.controller.js';
import MaybeModalController from './controllers/maybeModal.controller.js';
import ScrollbarController from './controllers/scrollbar.controller.js';
import NumberTextController from './controllers/numberText.controller.js';

import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de.js"

const application = Application.start();
application.register('tabs', TabsMultiStep);
application.register('slideover', MaybeSlideover);
application.register('form', Form);
application.register('sticky', Sticky);
application.register('slimselect', SlimSelect);
application.register('sameheight', SameHeightController);
application.register('popover', Popover);
application.register('summary', Summary);
application.register('recommendation', RecommendationController);
application.register('maybemodal', MaybeModalController);
application.register('scrollbar', ScrollbarController);
application.register('numberText', NumberTextController);


document.addEventListener('DOMContentLoaded', function() {
  flatpickr('#quotation_contract_start', {
    enableTime: false,
    locale: German,
    altInput: true,
    altFormat: "d.m.Y",
    dateFormat: "Y-m-d",
    enable: [
        function(date) {
            const now = new Date();

            if (date <= now) {
                return false;
            }

            return date.getDate() === 1;
        }
    ]
  })
})
