import { Slideover } from "tailwindcss-stimulus-components";
import { debounce } from "lodash";
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../config/tailwind.config.js';

export default class MaybeSlideover extends Slideover {

	tailwind = resolveConfig(tailwindConfig);

	initialize() {


		this.manageVisibility = debounce(
			this.manageVisibility,
			200,
			{ trailing: true }
		).bind(this);
	}

	/**
	 * Pseudo-connect function.
	 * Stimulus did not want to call connect() multiple times.
	 */
	fakeConnect() {

		super.connect();

		this.overlayTarget.classList.add('hidden');
		this.overlayTarget.classList.add('fixed');
		this.overlayTarget.classList.remove('relative');
		this.openValue = false;

		this.data.set('connected', true);
	}

	connect() {

		window.dispatchEvent(new CustomEvent('resize'));
	}

	disconnect() {

		this.overlayTarget.classList.remove('fixed');
		this.overlayTarget.classList.remove('hidden');
		this.menuTarget.classList.remove('hidden');
		this.overlayTarget.classList.add('relative');

		this.openValue = false;
		this.data.set('connected', false);
	}

	manageVisibility(_event) {
		this.overlayTarget.classList.remove('invisible');

		const breakpoint = parseInt(this.tailwind.theme.screens.lg);
		const width = window.innerWidth;
		const connected = this.data.get('connected');

		if (width >= breakpoint) {

			if (connected) {
				this.disconnect();
			}

			return;
		}

		this.fakeConnect();
	}
}
